import React from 'react';
import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import { ProtectedRouter } from 'modules/app';
import { NotFound } from 'modules/notFound';

const CompanyRoot = React.lazy(() => import('modules/profile/CompanyRoot'));
const TalentRoot = React.lazy(() => import('modules/profile/Root'));
const Settings = React.lazy(() => import('modules/settings/Settings'));

const Profile: React.FC<PageProps> = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      <Router basepath="/:lang/profile">
        <ProtectedRouter path={`talent/*`} Component={TalentRoot} />
        <ProtectedRouter path={`company/*`} Component={CompanyRoot} />
        <ProtectedRouter path={`settings/*`} Component={Settings} />
        <NotFound type="dashboardFull" default />
      </Router>
    </React.Suspense>
  );
};

export default Profile;
